@font-face {
    font-family: "gaegu";
    src: url("../fonts/gaegu.ttf");
  }

  @font-face {
    font-family: "leckerli";
    src: url("../fonts/LeckerliOne-Regular.ttf");
  }

  @font-face {
    font-family: "sourceserif";
    src: url("../fonts/SourceSerif4-VariableFont_opsz,wght.ttf");
  }

  @font-face {
    font-family: "outfit-sb";
    src: url("../fonts/Outfit-SemiBold.ttf");
  }

  @font-face {
    font-family: "outfit";
    src: url("../fonts/Outfit-Regular.ttf");
  }

  body {
    background-color: #E8CE98;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  ul:hover a {
    filter: blur(2px);
    opacity: 0.8;
  }

  li {
    list-style: none;
    margin: 0px 20px;
    transition: 0.5s;
  }

  a {
    display: block;
    position: relative;
    padding: 10px;
    font-size: 48px;
    font-family: outfit-sb;
    color: #9B6621;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.3s;
  }

  ul a:hover {
    transform: scale(1.2);
    opacity: 1;
    filter: blur(0);
  }

  a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F4BB45;
    border-radius: 8px;

    transition: 0.3s;
    transform: scale(0);
    z-index: -1;
  }

  a:hover:before {
    transform: scale(1);
  }

  nav {
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .version {
    font-family: outfit-sb;
    color: #9B6621;
  }

  .welcome-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;      /* Center horizontally */
    /* justify-content: center;  Center vertically */
    position: relative;
    /* overflow: hidden; */
  }

  .welcome-page #godot-iframe {
    aspect-ratio: 1 / 1;  /* Maintains square shape */
    max-width: 100%;
    max-height: 100%;
  }

  .welcome-page p {
    font-family: outfit;
    margin-left: 20%;
    margin-right: 20%;
    text-align: left;
  }

  @media (orientation: portrait) {
    .welcome-page #godot-iframe {
      width: 100%;
      height: auto;
      max-height: 100vh;
    }
  }

  @media (orientation: landscape) {
    .welcome-page #godot-iframe {
      height: 80vh;
      width: auto;
      max-width: 80vh; /* Ensures width does not exceed height */
    }
  }
